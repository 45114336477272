@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
.root {
    display: flex;
    outline: none;
}

.search-form input,
.search-form div,
.search-form label {
    font-size: 0.75rem;
}

.search-form .MuiInputLabel-shrink {
    -webkit-transform: translate(0, 1.5px) scale(1);
            transform: translate(0, 1.5px) scale(1);
}

.MuiContainer-root {
    margin: unset !important;
}
.loader-fallback {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(10,10,10,0.2);
    z-index: 1210;
    left: 0;
    top: 0;
}

.loader-fallback__icon {
    position: relative;
    left: 50%;
    top: 50%;
}
body {
    overflow:hidden !important;
    margin: 0;
    overflow-y : overlay;
    font-family: 'Open Sans';
    font-size: 12px;
}
main{
    width:100%;
    font-family: 'Open Sans', 'Roboto', 'Helvetica Neue', sans-serif !important;
    overflow:hidden;
}
table {
	text-align: center;
}
.addEllipsis{
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
}
.headerInfo {
	color: deepskyblue;
    position: relative;
    top: -3px;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.status-dot-green {
	position: inherit;
	height: 18px;
	width: 18px;
	background-color: #A4CF72;
	border-radius: 50%;
	display: inline-block;
}

.status-dot-red {
	position: inherit;
	height: 18px;
	width: 18px;
	background-color: #C5203E;
	border-radius: 50%;
	display: inline-block;
}

.status-dot-yellow {
	position: inherit;
	height: 18px;
	width: 18px;
	background-color: #FFDC62;
	border-radius: 50%;
	display: inline-block;
}

.status-dot-gray {
    position: inherit;
    height: 18px;
    width: 18px;
    background-color: #B0C0CF;
    border-radius: 50%;
    display: inline-block;
}

.dark-mode .status-dot-gray {
    background-color: #445A6F;
}

.status-refresh {
    background: url("/assets/svg/refresh.svg") center;
    background-repeat: no-repeat !important;
    background-size: cover;
    display: inline-block;
    height: 19px;
    width: 19px;
}

.status-dot-empty {
	position: inherit;
	height: 19px;
	width: 19px;
	border: 1px solid #90A6BB;
	border-radius: 50%;
	display: inline-block;
}

.preProcSub {
	position: inherit;
    margin-left: 15%;
	text-align: left;
}
.receipSub, .uploadSub {
	position: inherit;
    margin-left: 6%;
	text-align: left;
}

.center-align {
    text-align: center;
}
/* .preProcSub br {
    display: none;
} */

.deliverySub {
	position: relative;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    right: -19px;
    text-align: right;
}

.detailSubMilestone {
    position: inherit;
    text-align: right;
    display: flex;
    flex-direction: column; 
}

.subStatus {
	margin-left: 23px;
}

.delSubStatus {
    /* right: 5%;
    margin-top: 0%;
    position: absolute; */
    margin-right: 5px;
    color: #57748E;
}

.submile-margin {
    margin: 4px 0 4px 0;
    display: inline-flex;
}

.App-link {
	color: #61dafb;
}

.rowCount {
	float: right;
}


/*To make table header sticky*/
table {
  table-layout:fixed;
  /* position: relative; */
  border-collapse: collapse; 
}
table th {
    font-weight: bold;  /*padding: 2rem;*/
}
.table td, .table th {
    padding: 0.75rem 5.5px !important;
}
table tbody {
    background: #FAFAFA;
    overflow:hidden;
    overflow-y: auto;
}
.scrollTable {
    height: calc(100% - 50px) !important;
    /* background-color: #F6F6F6;
    color: #445A6F; */
}
.scrollTable table tbody, .scrollTable table thead tr th {
    background-color: #F6F6F6 !important;
    background: #F6F6F6 !important;
    color: #445A6F !important;
}
.scrollTable table tbody .row-active .playlist-down {
    display: block !important;
}
.scrollTable table tbody .row-active .playlist-right {
    display: none !important;
}
.scrollTable table th{
    padding-bottom: 5px;
}
table tbody tr {
    border-bottom: 1px solid #EAEAEA;
    /* color: #32406D; */
}
/* th, td {
  padding: 0.25rem;
} */

th {
  background:#fafafa;
  top: 0; 
  /* box-shadow: 0 2px 2px -1px rgba(250, 240, 240, 0.4); */
  font-size: 12px;
  /* color: #30404F; */
  line-height: 16px;
}

th:focus {
    outline: none;
    box-shadow: none;
}

.table thead th{
    border-bottom: 1px solid #90A6BB !important;
    box-shadow: 0px 1px 0px 0px #90A6BB;
}

.table td, .table th{
    border-top: none !important;
    /* font-family:"Open Sans", "Helvetica Neue", sans-serif;
    font-feature-settings:normal;
    font-kerning:auto;
    font-optical-sizing:auto;
    font-size:12px; */
}
/*To make table header sticky Ends*/

.hide {
	display: none;
}

.update{
margin-left: 15%;	
}
.live{
	margin-left: 13%;	
    }

    /* tbody {
        display:block;
        width:100%;
        overflow:auto;
    }
    thead, tbody tr {
        display:table;
        width:100%;
        table-layout:fixed;
    } */

#acquire-table tbody, #asset-table tbody {
    display:block;
    width:100%;
    overflow:auto;
    height: calc(100% - 72px);   /* subtracting height of header */
}

#asset-table tbody {
    height: calc(100% - 89px);   /* subtracting height of header */
}

#acquire-table thead, #acquire-table tbody tr,
#asset-table thead, #asset-table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.main-table-body {
    /* height: calc(100vh - 345px); */
}

/* Pagination CSS */

.page-item.disabled .page-link{
    background-color: #D0D9E2 !important;
    color:#B0C0CF !important;
}

.page-item.active .page-link{
    background-color: #FAFAFA !important;
    border: 1px solid  #718DA8 !important;
    color: #718DA8 !important;
}

.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link{
    padding: 2px 5px;
    font-size: 8px
}


td.reset-expansion-style {
    padding: 10px 0 !important;
}

/*Dark Mode Starts*/

.dark-mode,
.dark-mode select,
.dark-mode select option,
.dark-mode input,
.dark-mode button,
.dark-mode th,
.dark-mode tbody tr,
.dark-mode #pageDropDown+ul,
.dark-mode #pageDropDown+ul li,
.dark-mode #pageDropDown+ul li>a,
.dark-mode  #pageDropDown,
.dark-mode .MuiCardContent-root,
.dark-mode .MuiButtonBase-root,
.dark-mode .MuiAccordionDetails-root,
.dark-mode .MuiPaper-root,
/* .dark-mode .MuiToolbar-root{
	background: #1B2329 !important;;
	background-color: #1B2329 !important;;
	color: whitesmoke !important;
} */
/* .dark-mode td.reset-expansion-style{
	background-color: #232e38;
} */
/* .dark-mode tbody tr:hover

 {
	background: #2a3640 !important;
	background-color: #2a3640 !important;
	color: whitesmoke !important;
} */


.dark-mode .dropdown-menu{
	
}
.dark-mode #pageDropDown{
    font-size: 13px;
        background-color: #1D272F;
        color:#829AB2 !important;
        border-color: #1D272F;
        border-bottom-color:#829AB2;
}

.dark-mode table tbody, .dark-mode th {
    background: #1b2329af;
}

.dark-mode .scrollTable table tbody, .dark-mode .scrollTable table thead tr th {
    background-color: #1B2329 !important;
    background: #1B2329 !important;
    color: #DADADA !important;
}

.dark-mode .table thead th{
    border-bottom: 1px solid #445A6F !important;
    box-shadow: 0px 1px 0px 0px #445A6F;
    color: #DADADA;
}

.dark-mode .table td{
    color: #FAFAFA
}

.dark-mode table tbody tr{
    border-bottom: 1px solid #1D272F;
}

.dark-mode .table-hover tbody tr:hover{
    background: #2a3640;
}

.dark-mode .reset-expansion-style{
    background: #1D272F;
}

.dark-mode .subMilestone-text, .dark-mode .delSubStatus{
    color: #FAFAFA;
}

.dark-mode .hasTooltip:hover .msHover{
    background: #445A6F;
    color: #FFFFFF;
}

.dark-mode .hasTooltip .msHover:after{
    border-color: #445A6F transparent transparent transparent;
}

.dark-mode ::-webkit-scrollbar-track{
    background: #1D272F;
    border-left: 3px solid #1B2329;
    border-right: 3px solid #1B2329;
}

.dark-mode ::-webkit-scrollbar-thumb {
    background: #445A6F;
}

.dark-mode .page-item.disabled .page-link{
    background-color: #30404F !important;
    color: #445A6F !important;
    border: 1px solid #30404F !important;
}

.dark-mode .page-item.active .page-link{
    background-color: #1B2329 !important;
    border: 1px solid  #718DA8 !important;
    color: #718DA8 !important;
}

/*Dark Mode Ends*/
#pageDropDown{
    font-size: 13px;
        background-color: #FAFAFA;
        color:#829AB2;
        border-color: #FAFAFA;
        border-bottom-color:#829AB2;
}
#pageDropDown+ul a{
    font-size: 13px;
    color:#829AB2;

}

#pageDropDown li:hover{
	background: rgba(0,0,0,.075) !important;
}


.msHover::after{
	content:'';
    position: absolute;
    top: 100%;
    left:50%;

    width: 0;
    height: 0;
	border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #D0D9E2;
}
.hasTooltip span {
    display: none;
}

/* ----- tool tip css -------- */
.hasTooltip .msHover {
    display: none;
}

.hasHeaderTooltip {
    margin-left: 3px;
}

.hasHeaderTooltip .msHover {
    display: none;
}   
.hasTooltip:hover .msHover { 
    display: block;
    position: fixed;
    border-radius: 4px;
    color: #445A6F;
    padding: 5px 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 20%;
    white-space: pre-wrap;
    font-size: 10px;
    line-height: 14px;
    background: #D0D9E2;
    text-align: left;

}
.hasTooltip:hover .msHoverDel { 
    max-width: 12%;
}

.hasTooltip:hover .msHoverProxy{
    max-width: 9.6%;
}

.hasTooltip .msHover:after{
    content: " ";
    position: absolute;
    bottom: 100%;
    bottom: -23px;
    -webkit-transform: translateX(-50%) ;
            transform: translateX(-50%) ;
    left:50%;
    border-width: 6px;
    border-style: solid;
    border-color: #D0D9E2  transparent transparent transparent;
  }  
.hasTooltip{
    overflow: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* ----- submilestone tool tip css -------- */
.hasTooltip .subMsHover {
    display: none;
    z-index: 1;
}

.hasTooltip:hover .subMsHover { 
    display: block;
    position: fixed;
    border-radius: 4px;
    color: #445A6F;
    padding: 5px 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 20%;
    white-space: pre-wrap;
    font-size: 10px;
    line-height: 14px;
    background: #D0D9E2;
    text-align: left;
}

.hasTooltip .subMsHover:after{
    content: " ";
    position: absolute;
    bottom: 17px;
    left: 100%;
    border-width: 4px;
    height: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #D0D9E2;
}  
.hasTooltip{
    overflow: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multi-line{
    text-align: left !important;
}

.showCursor{
    cursor: pointer;
}

.subMilestone-wrapper {
    cursor: pointer;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 7px 0;
}

.subMilestone-text {
    margin-left: 5px;
    color: #57748E
}

.subMilestone-wrapper--inverted {
    cursor: pointer;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 7px 0;
    justify-content: space-between;
    min-width: 110px;
}

.subMilestone-text--inverted {
    color: #57748E
}

.milestone.showCursor.active::before {
    width: 20px;
    height: 20px;
    position: absolute;
    content: "";
    border-radius: 50%;
}

/* .milestone.showCursor {
    display: inline-block
} */

.showCursor.active .clickable{
    top: 1px;
    left: 1px;
}

.arrow-down {
	display: none;
	position: absolute;	
	width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #D0D9E2;
  }

.subMileDetail {
    align-items: center;
    display: flex;
    position: relative;
}

.subMile{
	position: inherit;
    margin-left: 41.8%;
    display: flex;
    align-items: center;
  }

  .subMileDel{
    position: relative;
    display: flex;
    align-items: center;
    right: 0;
  }

  .subMileDel--endpoint-fulfillment {
  }

  .subMileDel--receipt {
    right:  -3px;
  }

  .subMileLabel{
    position: relative;
    background-color: #718DA8;
    color: #FAFAFA;
    border-radius: 15px;
    width: auto;
	padding: 3px 12px;
    cursor: pointer;
    /* left : 50%; */
}

.subMileLabelDetails {
    background-color: #718DA8;
    border-radius: 15px;
    color: #FAFAFA;
    cursor: pointer;
    padding: 3px 12px;
    position: absolute;
    width: auto;
}

.subMileLabelDetails--mediaRequest {
   left: -210px;
}

.subMileLabelDetails--qmAvailable {
    left: -140px;
}

.subMileLabelDetails--mezzFulfilment {
   left: -155px;
}

.subMileLabelDetails--receipt {
   left: -110px;
}

.subMileLabelDetails--upload {
   left: -100px;
}

.subMileLabelDel{
	position: absolute;
    background-color: #718DA8;
    color: #FAFAFA;
    border-radius: 15px;
    width: auto;
    padding: 3px 12px;
    cursor: pointer;
    right: 120px;
}

.totCount{
	border-style: solid;
    border-color: #E5E5E5;
    border-width: 7px;
    border-radius: 7px;
    font-size: 11px;
}

.headerTootTip{
    top: 213px;
    z-index: 1;
}

.header-index{
    z-index: 2;
}
.header-width-8{
	width: 8%;
}
.header-width-11{
	width: 11%;
}
.headerHover
{
    display: none;
    position: absolute;
    border-radius: 4px;
    color: #445A6F;
    padding: 5px 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 17%;
    white-space: pre-wrap;
    font-size: 10px;
    line-height: 14px;
    background: #D0D9E2;
	text-align: left;
}
.detailsColumn {
    width: 50px;
}
.leftAlign{
    text-align: left;
    z-index: 2;
    background: #F6F6F6;
}
.centerAlignViews{
    text-align: center;
    z-index: 2;
}

#asset-table .leftAlign {
    z-index: unset;
}

#esp-table .leftAlign{
    position: -webkit-sticky;
    position: sticky;
}

.dark-mode .leftAlign{
    background: #1B2329;
}
.centerAlign{
    text-align: center;
}
.rightAlign{
    text-align: right;
}
.header-align{
    display: flex;
    justify-content: center;
}
.header__text {
    width: -webkit-min-content;
    width: min-content;
}
/* .header__text--media-request,
.header__text--cam {
    min-width: 100px;
} */

.align-top{
    vertical-align: top;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: #DADADA;
    border-radius: 5px;
    background-clip: padding-box;
    border: 2px solid transparent;
}

::-webkit-scrollbar-track {
    background: #EAEAEA;
    border-left: 3px solid #f6f6f6;
    border-right: 3px solid #f6f6f6;
    border-radius: 5px;
}

.pagination{
    position: absolute;
    right: 6%;
    width: auto;
}
.pagination>li>a{
    position: relative;
    float: left;
    padding: 2px 6px;
    line-height: 14px;
    color: white;
    text-decoration: none;
    background-color: #718DA8;
    border-color: #718DA8;
    font-size: 10px;
}
.pagination>li>a:hover{

    color: white;
    background-color: rgb(96 114 132);
}
.pagination>li{
    margin-left: 2%;
}
.react-bs-table-no-data {
    font-size: 14px;
    padding: 1.7rem !important;
}
.react-bs-table-no-data::parent {
    border-bottom: none;
}
.makeRound{
    border-radius: 50%;
    border: 1px #a2a2a2 solid;

}
.makeRound{
    border-radius: 50%;
    border: 1px #a2a2a2 solid;

}
.page-item a{
    border-radius: 50% !important;
    border: 1px #a2a2a2 solid;

}
.maxWidth{
    width:100%;
}


.react-datepicker {
    font-size: 1em !important;
    font-family: "Roboto Noto sans-serif" !important;
    outline: none !important;
    width: 233px !important;
}

.react-datepicker-wrapper:focus {
    outline: none
}

.react-datepicker__month {
    margin: 0.2rem !important;
    text-align: center;
}

.react-datepicker__day-names {
    text-align: left;
}

.react-datepicker button:focus {
    outline: none;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
     right: 0px !important; 
}

.react-datepicker__time-container {
    width: 80px !important;
    border-left: none !important;
}

.react-datepicker__month-container {
    width: 150px !important;
    /* border-left: none; */
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
    top: 4px !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.0rem !important;
    line-height: 0.7rem !important;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day{
    height: 15px;
    width: 15px;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left:0px !important;
}



.react-datepicker__day:hover {
    background-color: orange !important;
    color: #000;
}

/* .react-datepicker__time-list-item:hover{
    color: white;
} */
.react-datepicker__day--selected {
    color: #000;
}
.react-datepicker__day--selected:focus{
    outline-color : #216ba5;
}
.react-datepicker__month-read-view,.react-datepicker__year-read-view--selected-year{
    font-weight: bold;
}
.react-datepicker__header--time{
    display : none;
}

.react-datepicker__time{
    border: 1px solid #aeaeae;
    margin-left: 6px;
}
.react-datepicker__time-container{
    margin-top: 62px;
}
.react-datepicker__day{
    border: 1px solid #aeaeae;
    background-color: #F5F5F5;
    /* line-height: 1.6em !important; */

}
/* .react-datepicker__time-list {
    height: 119px;
} */
.react-datepicker__time-list-item{
    height: 17px !important;
    padding: 4px 8px !important;
    border-bottom: 1px solid #aeaeae;
    border-right: 1px solid #aeaeae;
    background-color: #F5F5F5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 71px !important;
}

.react-datepicker__header{
    background-color:white !important;
    width:233px;
}

.react-datepicker__time-box ul,
.react-datepicker__time-list {
    max-height: 101px;
    overflow-y: scroll;
    border: 1px solid #aeaeae;
    /* padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    box-sizing: content-box; */
}
.react-datepicker__time {
    border: none !important;
}
.react-datepicker-popper {
    z-index: 2 !important;
}

.lastColumn::after{
    left:90%;
}
.acq-last::after{
    left:88%;
}
.acq-last{
    margin-left:0px;
}
.MuiTypography-body1{
    font-size : 10px !important;
}
.MuiDialog-paperWidthMd{
    max-width: 1000px !important;
}
tbody{
    font-size :.75rem;
}
.MuiAutocomplete-listbox{
    padding-bottom : 10px !important;
}


.react-bootstrap-table {
    width:100%;
    z-index: 1;
    margin: auto;
    background: #F6F6F6;
}

#esp-table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

#asset-table thead th {
    position: relative;
}

.esp-table-wrapper::-webkit-scrollbar-track {
    margin-top: 90px;
}

#esp-table thead .leftAlign {
    z-index: 3;
}

#esp-table {
    width: calc(100vw + 25vw);
    min-width: 1280px;
}

.esp-table-wrapper {
    position: relative;
    overflow: auto;
    height: calc(100vh - 257px);
}

#acquire-table, #asset-table {
    height: 100%;
}

.milestone-search {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transform: translateX(-80%);
            transform: translateX(-80%);
    bottom: 0;
}

.avod-icon {
    fill: #718DA8
}

.avod-text {
    fill: #FFFFFF
}

.dark-mode .avod-icon {
    fill: #445A6F
}

.dark-mode .react-bootstrap-table{
    background-color: #1B2329;
}
.dark-mode ::-webkit-scrollbar-corner {
     background: #1B2329; 
}
::-webkit-scrollbar-corner{
    background: #F6F6F6
}

/* #asset-table .leftAlign{
    position: -webkit-sticky;
    position: sticky;
}

#asset-table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

#asset-table {
    width: calc(100vw + 25vw);
    min-width: 1280px;
} */

#asset-row-details {
    margin-bottom: 0px !important;
}
.assetview-table-body td.reset-expansion-style {
    padding-bottom: 0% !important;
}
