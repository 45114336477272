.loader-fallback {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(10,10,10,0.2);
    z-index: 1210;
    left: 0;
    top: 0;
}

.loader-fallback__icon {
    position: relative;
    left: 50%;
    top: 50%;
}