.root {
    display: flex;
    outline: none;
}

.search-form input,
.search-form div,
.search-form label {
    font-size: 0.75rem;
}

.search-form .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1);
}

.MuiContainer-root {
    margin: unset !important;
}